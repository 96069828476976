export const TITLE = "Cell Viability (%) of Cells exposed to Nanomaterials";

export const DESCRIPTION = "XGBoost model to predict the cell viability (%) due to exposure to ENMs. The model was developed on data collected from the Labouta HI, Asgarian N, Rinker K, Cramb DT. Meta-Analysis of Nanoparticle Cytotoxicity via Data-Mining the Literature. ACS Nano. 2019;13(2). doi:10.1021/acsnano.8b07562 publication.";

export const TAB_TITLE = "Cell Viability (%)";

export const MODEL_ID = "1yHvfoNZRGGgzAPYBksu";

export const GUIDE = {
    "Nanoparticle": 'Nanoparticle',
    "Type of Nanoparticle" : 'Type:_Organic_(O)/inorganic_(I)',
    "Diameter (nm)": 'Diameter_(nm)',
    "Concentration (μΜ)": "Concentration_μM", 
    "Exposure time (h)": 'Exposure_time_(h)',
    "Cells": 'Cells_',
    "Biochemical metric": 'Biochemical_metric_'
}

export const LIMITS = {
    "Nanoparticle": '',
    "Type of Nanoparticle" : '',
    "Diameter (nm)": '1.0 - 957.0 (nm)',
    "Concentration (μΜ)": "1.66e-20- 15000 (μM)", 
    "Exposure time (h)": '1 - 336 (h)',
    "Cells": '',
    "Biochemical metric": ''
}

export const CONVERTER = {
    "Type of ENM": {
        "Organic": 1,
        "Inorganic": 0,
    },
    "Nanomaterial": {
        'CuS': 0,
        'QDs': 1,
        'Pt': 2,
        'Polystyrene': 3,
        'MnO': 4,
        'CeO2': 5,
        'Co': 6,
        'Bi': 7,
        'Dendrimer': 8,
        'Au': 9,
        'Cr': 10,
        'Mo': 11,
        'Ti': 12,
        'Ag': 13,
        'Carbon Nanotubes': 14,
        'Se': 15,
        'Hydroxyapatite': 16,
        'Cu2O': 17,
        'SLN': 18,
        'TiO2': 19,
        'Carbon NP': 20,
        'Liposomes': 21,
        'CuO': 22,
        'ZnO': 23,
        'PLGA': 24,
        'CdO': 25,
        'Eudragit RL': 26,
        'Al2O3': 27,
        'Co3O4': 28,
        'Iron oxide': 29,
        'SiO2': 30,
        'Chitosan': 31,
        'MgO': 32
    },
    "Cells": {
        'Cells_A431': 0,
        'Cells_A549': 0,
        'Cells_AGS': 0,
        'Cells_B_cells': 0,
        'Cells_C18–4': 0,
        'Cells_CD3+_T_cells': 0,
        'Cells_CD4+T_cells': 0,
        'Cells_CDBgeo': 0,
        'Cells_CHO': 0,
        'Cells_COS': 0,
        'Cells_Caco2': 0,
        'Cells_Calu3': 0,
        'Cells_Clone9': 0,
        'Cells_ECV304': 0,
        'Cells_EJ28': 0,
        'Cells_Fibroblasts': 0,
        'Cells_H4': 0,
        'Cells_HAEC': 0,
        'Cells_HCMEC': 0,
        'Cells_HDF': 0,
        'Cells_HEK293': 0,
        'Cells_HMEC1': 0,
        'Cells_HMM': 0,
        'Cells_HUVEC': 0,
        'Cells_HaCaT': 0,
        'Cells_HeLa': 0,
        'Cells_HepG2': 0,
        'Cells_IMR90': 0,
        'Cells_IP15': 0,
        'Cells_J774': 0,
        'Cells_KEC': 0,
        'Cells_L929': 0,
        'Cells_LLCPK1': 0,
        'Cells_LoVo': 0,
        'Cells_Lymphocytes': 0,
        'Cells_L02': 0,
        'Cells_MCF7': 0,
        'Cells_MDAMB231': 0,
        'Cells_MDCK': 0,
        'Cells_MG63': 0,
        'Cells_Monocytes': 0,
        'Cells_NCIH441': 0,
        'Cells_NK_cells': 0,
        'Cells_NR8383': 0,
        'Cells_Naive_Tcell': 0,
        'Cells_Neuro2a': 0,
        'Cells_PAECs': 0,
        'Cells_PC12': 0,
        'Cells_PC3': 0,
        'Cells_PMA_activated_THP1': 0,
        'Cells_RAW_264.7': 0,
        'Cells_SHSY5Y': 0,
        'Cells_SKOV3': 0,
        'Cells_SKBR3': 0,
        'Cells_SKMel28': 0,
        'Cells_SVEC410': 0,
        'Cells_T98G': 0,
        'Cells_THP1': 0,
        'Cells_UMUC3': 0,
        'Cells_V14': 0,
        'Cells_VERO': 0,
        'Cells_hTERTBJ1': 0,
        'Cells_primary_alveolar_Macrophage': 0,
        'Cells_primary_alveolar_epithelial_cells': 0,
        'Cells_primary_tissue_Macrophage': 0
    },
    "Biochemical metric":{
        'Biochemical_metric_ATP_content': 0,
        'Biochemical_metric_LDH_leakage': 0,
        'Biochemical_metric_cell_membrane_integrity': 0,
        'Biochemical_metric_cell_metabolic_activity': 0,
        'Biochemical_metric_lysosomal_uptake': 0,
        'Biochemical_metric_protease_activity': 0
    }
}

export const ACCEPTABLE_VALUES = {
    "Cells": [
        "A431", "A549", "AGS", "B Cells", "C18-4", "CD3+ T Cells", "CD4+ T Cells", "CDBgeo", "CHO", "COS", 
        "Caco2", "Calu3", "Clone9", "ECV304", "EJ28", "Fibroblasts", "H4", "HAEC", "HCMEC", "HDF", "HEK293", 
        "HMEC1", "HMM", "HUVEC", "HaCaT", "HeLa", "HepG2", "IMR90", "IP15", "J774", "KEC", "L929", "LLCPK1", "LoVo", 
        "Lymphocytes", "L02", "MCF7", "MDAMB231", "MDCK", "MG63", "Monocytes", "NCIH441", "Nk Cells", "NR8383", 
        "Naive Tcell", "Neuro2a", "PAECs", "PC12", "PC3", "Pma Activated Thp1", "Raw 264.7", "SHSY5Y", "SKOV3", 
        "SKBR3", "SKMel28", "SVEC410", "T98G", "THP1", "UMUC3", "V14", "VERO", "hTERTBJ1", "Primary Alveolar Macrophage", 
        "Primary Alveolar Epithelial Cells", "Primary Tissue Macrophage", "Other"
        ],
    "Biochemical metric": ["ATP Content", "LDH Leakage", "Cell Membrane Integrity", "Cell Metabolic Activity", "Lysosomal Uptake", "Protease Activity", "Other"]
}