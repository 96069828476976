import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms'
import { MatTable } from '@angular/material/table'
import { GUIDE, CONVERTER, LIMITS } from '../../assets/project-constants'


export interface PredictionFields{
  id: any;
  pred: any;
}


@Component({
  selector: 'feature-table-component',
  styleUrls: ['feature-table.component.scss'],
  templateUrl: 'feature-table.component.html',
  
})


export class FeatureTableComponent implements OnInit {


  @ViewChild(MatTable, { static: true }) table: MatTable<any> 
  
  displayedColumns: string[] = ['ID']
  displayedHead:string[]=['ID']
  displayedFields:string[] = ['ID']
  
  myformArray = new FormArray([])
  dataSource = this.myformArray.controls;

  goToPrediction:boolean = false
  sendFeatures = []
  ids = []
  limits = LIMITS
  constructor() { }

  ngOnInit() {
    

      this.displayedColumns = this.displayedColumns.concat(Object.keys(GUIDE))
      this.displayedColumns.push("delete")
      this.displayedHead = this.displayedHead.concat(Object.keys(GUIDE))
      this.displayedFields = this.displayedFields.concat(Object.keys(GUIDE))
      // add first row
      const newGroup=new FormGroup({})
      this.displayedFields.forEach(x=>{
        newGroup.addControl(x,new FormControl())
      })
      this.myformArray.push(newGroup)
      this.dataSource = [...this.myformArray.controls];      

  }

  receiveData($event){
    this.dataSource = $event
    this.myformArray.controls = $event
  }

  delete(index: number) {
    this.myformArray.removeAt(index);
    this.table.renderRows()
    this.dataSource = [...this.myformArray.controls];


  }
  add() {
    const newGroup=new FormGroup({});
    this.displayedFields.forEach(x=>{
      newGroup.addControl(x,new FormControl())
    })
    this.myformArray.push(newGroup)

    this.dataSource = [...this.myformArray.controls];
  }
  

  submit(){
    this.sendFeatures = [];
    this.ids = [];
    
    this.myformArray.controls.forEach( (element) => {
      this.ids.push(element.value.ID);
      let currSample = {}
      for (const [key, value] of Object.entries(element.value)) {
        if (key!='ID'){
          if (key=='Nanoparticle'){
            currSample[GUIDE[key]] = CONVERTER['Nanomaterial'][String(value)]
          } else if (key=='Cells') {
            if (String(value)=='Other'){
              for (const [k, v] of Object.entries(CONVERTER['Cells'])) {
                currSample[k] = v
              }
            } else {
              for (const [k, v] of Object.entries(CONVERTER['Cells'])) {
                if (k == GUIDE[key]+String(value)){
                  currSample[k] = 1 
                } else {
                  currSample[k] = 0
                }
              }
            }
          } else if (key=='Biochemical metric') {
            if (String(value)=='Other'){
              for (const [k, v] of Object.entries(CONVERTER['Biochemical metric'])) {
                currSample[k] = v
              }
            } else {
              for (const [k, v] of Object.entries(CONVERTER['Biochemical metric'])) {
                if (k == GUIDE[key]+String(value)){
                  currSample[k] = 1 
                } else {
                  currSample[k] = 0
                }
            }}
          } else {
            currSample[GUIDE[key]] = Number(value)
          }
            
        }      
      }
      this.sendFeatures.push(currSample)
    });

    this.goToPrediction = true;
  }

}
