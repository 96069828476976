<button mat-button (click)="add()" class="button-class">Add row</button>
<button mat-button (click)="submit()" class="button-class">Submit</button>

<div style="display: inline-flex;">
  <div class= "left-section">
    <table style="height: 10px" mat-table [dataSource]="dataSource" class="mat-elevation-z8 feature-data">


      <ng-container *ngFor="let col of displayedFields;let i=index" [matColumnDef]="col">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;margin:1em"> {{displayedHead[i]}} <br> {{limits[col]}}</th>
        <td mat-cell *matCellDef="let element">
          
          <input *ngIf="col != 'Nanoparticle' && col != 'Cells' && col != 'Biochemical metric' && col != 'Type of Nanoparticle'; else nps" [formControl]="element.get(col)" style="width:110px ; margin:1em">

          <ng-template #nps>
            <mat-select *ngIf="col == 'Nanoparticle'; else cells" placeholder="CuS" [formControl]="element.get(col)" style="width:120px; margin:1em">
                <mat-option value="CuS">CuS</mat-option>
                <mat-option value="QDs">QDs</mat-option>
                <mat-option value="Pt">Pt</mat-option>
                <mat-option value="Polystyrene">Polystyrene</mat-option>
                <mat-option value="MnO">MnO</mat-option>
                <mat-option value="CeO2">CeO2</mat-option>
                <mat-option value="Co">Co</mat-option>
                <mat-option value="Bi">Bi</mat-option>
                <mat-option value="Dendrimer">Dendrimer</mat-option>
                <mat-option value="Au">Au</mat-option>
                <mat-option value="Cr">Cr</mat-option>
                <mat-option value="Mo">Mo</mat-option>
                <mat-option value="Ti">Ti</mat-option>
                <mat-option value="Ag">Ag</mat-option>
                <mat-option value="Carbon Nanotubes">Carbon Nanotubes</mat-option>
                <mat-option value="Se">Se</mat-option>
                <mat-option value="Hydroxyapatite">Hydroxyapatite</mat-option>
                <mat-option value="Cu2O">Cu2O</mat-option>
                <mat-option value="SLN">SLN</mat-option>
                <mat-option value="TiO2">TiO2</mat-option>
                <mat-option value="Carbon NP">Carbon NP</mat-option>
                <mat-option value="Liposomes">Liposomes</mat-option>
                <mat-option value="CuO">CuO</mat-option>
                <mat-option value="ZnO">ZnO</mat-option>
                <mat-option value="PLGA">PLGA</mat-option>
                <mat-option value="CdO">CdO</mat-option>
                <mat-option value="Eudragit RL">Eudragit RL</mat-option>
                <mat-option value="Al2O3">Al2O3</mat-option>
                <mat-option value="Co3O4">Co3O4</mat-option>
                <mat-option value="Iron oxide">Iron oxide</mat-option>
                <mat-option value="SiO2">SiO2</mat-option>
                <mat-option value="Chitosan">Chitosan</mat-option>
                <mat-option value="MgO">MgO</mat-option>
            </mat-select>
          
        </ng-template>
        
        <ng-template #cells>
            <mat-select *ngIf="col == 'Cells'; else metric" placeholder="A431" [formControl]="element.get(col)" style="width:120px; margin:1em">
                <mat-option value="A431">A431</mat-option>
                <mat-option value="A549">A549</mat-option>
                <mat-option value="AGS">AGS</mat-option>
                <mat-option value="B_cells">B Cells</mat-option>
                <mat-option value="C18–4">C18-4</mat-option>
                <mat-option value="CD3+_T_cells">CD3+ T Cells</mat-option>
                <mat-option value="CD4+T_cells">CD4+ T Cells</mat-option>
                <mat-option value="CDBgeo">CDBgeo</mat-option>
                <mat-option value="CHO">CHO</mat-option>
                <mat-option value="COS">COS</mat-option>
                <mat-option value="Caco2">Caco2</mat-option>
                <mat-option value="Calu3">Calu3</mat-option>
                <mat-option value="Clone9">Clone9</mat-option>
                <mat-option value="ECV304">ECV304</mat-option>
                <mat-option value="EJ28">EJ28</mat-option>
                <mat-option value="Fibroblasts">Fibroblasts</mat-option>
                <mat-option value="H4">H4</mat-option>
                <mat-option value="HAEC">HAEC</mat-option>
                <mat-option value="HCMEC">HCMEC</mat-option>
                <mat-option value="HDF">HDF</mat-option>
                <mat-option value="HEK293">HEK293</mat-option>
                <mat-option value="HMEC1">HMEC1</mat-option>
                <mat-option value="HMM">HMM</mat-option>
                <mat-option value="HUVEC">HUVEC</mat-option>
                <mat-option value="HaCaT">HaCaT</mat-option>
                <mat-option value="HeLa">HeLa</mat-option>
                <mat-option value="HepG2">HepG2</mat-option>
                <mat-option value="IMR90">IMR90</mat-option>
                <mat-option value="IP15">IP15</mat-option>
                <mat-option value="J774">J774</mat-option>
                <mat-option value="KEC">KEC</mat-option>
                <mat-option value="L929">L929</mat-option>
                <mat-option value="LLCPK1">LLCPK1</mat-option>
                <mat-option value="LoVo">LoVo</mat-option>
                <mat-option value="Lymphocytes">Lymphocytes</mat-option>
                <mat-option value="L02">L02</mat-option>
                <mat-option value="MCF7">MCF7</mat-option>
                <mat-option value="MDAMB231">MDAMB231</mat-option>
                <mat-option value="MDCK">MDCK</mat-option>
                <mat-option value="MG63">MG63</mat-option>
                <mat-option value="Monocytes">Monocytes</mat-option>
                <mat-option value="NCIH441">NCIH441</mat-option>
                <mat-option value="NK_cells">Nk Cells</mat-option>
                <mat-option value="NR8383">NR8383</mat-option>
                <mat-option value="Naive_Tcell">Naive Tcell</mat-option>
                <mat-option value="Neuro2a">Neuro2a</mat-option>
                <mat-option value="PAECs">PAECs</mat-option>
                <mat-option value="PC12">PC12</mat-option>
                <mat-option value="PC3">PC3</mat-option>
                <mat-option value="PMA_activated_THP1">Pma Activated Thp1</mat-option>
                <mat-option value="RAW_264.7">Raw 264.7</mat-option>
                <mat-option value="SHSY5Y">SHSY5Y</mat-option>
                <mat-option value="SKOV3">SKOV3</mat-option>
                <mat-option value="SKBR3">SKBR3</mat-option>
                <mat-option value="SKMel28">SKMel28</mat-option>
                <mat-option value="SVEC410">SVEC410</mat-option>
                <mat-option value="T98G">T98G</mat-option>
                <mat-option value="THP1">THP1</mat-option>
                <mat-option value="UMUC3">UMUC3</mat-option>
                <mat-option value="V14">V14</mat-option>
                <mat-option value="VERO">VERO</mat-option>
                <mat-option value="hTERTBJ1">hTERTBJ1</mat-option>
                <mat-option value="primary_alveolar_Macrophage">Primary Alveolar Macrophage</mat-option>
                <mat-option value="primary_alveolar_epithelial_cells">Primary Alveolar Epithelial Cells</mat-option>
                <mat-option value="primary_tissue_Macrophage">Primary Tissue Macrophage</mat-option>
                <mat-option value="Other">Other</mat-option>
            </mat-select>
          </ng-template>

          <ng-template #metric>
            <mat-select *ngIf="col == 'Biochemical metric'; else type" placeholder="ATP Content" [formControl]="element.get(col)" style="width:120px; margin:1em">
                <mat-option value="ATP_content">ATP Content</mat-option>
                <mat-option value="LDH_leakage">LDH Leakage</mat-option>
                <mat-option value="cell_membrane_integrity">Cell Membrane Integrity</mat-option>
                <mat-option value="cell_metabolic_activity">Cell Metabolic Activity</mat-option>
                <mat-option value="lysosomal_uptake">Lysosomal Uptake</mat-option>
                <mat-option value="protease_activity">Protease Activity</mat-option>
                <mat-option value="Other">Other</mat-option>
            </mat-select>
          </ng-template>
          
          <ng-template #type>
            <mat-select placeholder="Inorganic" [formControl]="element.get(col)" style="width:120px; margin:1em">
              <mat-option value="0">Inorganic</mat-option>
              <mat-option value="1">Organic</mat-option>
            </mat-select>
          </ng-template>
          
          <!-- <ng-template #time>
            <mat-select placeholder="3h" [formControl]="element.get(col)" style="width:120px; margin:1em">
              <mat-option value="3">3h</mat-option>
              <mat-option value="24">24h</mat-option>
            </mat-select>
          </ng-template> -->
        </td>
      </ng-container>

        <!---column delete-->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element;let i=index;">
            <button mat-button (click)="delete(i)">Remove</button>
        </td>
    
      </ng-container>
    
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
      
    <div *ngIf="goToPrediction">
      <app-predictions class="center-spin" [sendFeatures]="sendFeatures" [ids]="ids"></app-predictions>
    </div>
  </div>
  
  <div class="right-section">
    <app-dataset-csv (sendData)="receiveData($event)"></app-dataset-csv>
    <app-documentation></app-documentation>
   
    <mat-card class="nano-card">
      <mat-card-header>
          <img src="assets/SmallLogo.jpg" class="nanosolveit-logo">
      </mat-card-header>
      <mat-card-content>
        
        <br><br>
        <p>
          This work received funding from the European Union's Horizon 2020 research and innovation program via 
          <a href="http://www.nanosolveit.eu">NanoSolveIT project</a>
          under grant agreement number 814572.
        </p>
      </mat-card-content>
      
    </mat-card>
    
    

  </div>

</div>


