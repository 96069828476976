import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-ask-for-id',
  templateUrl: './ask-for-id.component.html',
  styleUrls: ['./ask-for-id.component.scss']
})

export class AskForIdComponent implements OnInit {

  selected = ''

  possible_ids:string[] = []
  verify:boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.possible_ids.push("None");
    this.data.possibilites.forEach(id => {
      this.possible_ids.push(id)
    })
  }

  idChanged($event){
    this.verify = true
  }

}