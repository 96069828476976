<mat-card class="doc-card">  

    <mat-card-header>
        <mat-card-title>Documentation</mat-card-title>
    </mat-card-header>
    
    <mat-card-content>
        <p>
        Download the QMRF Report of the model and a tutorial on how to use this app.
        </p>
    </mat-card-content>
    
    <mat-card-actions>
        
        <button matTooltip="Download Tutorial of the Application" mat-icon-button color="primary" (click)="downloadTutorial()">
        <mat-icon>
            <span class="material-icons-outlined">
                file_download
            </span>
        </mat-icon>
        </button>

        <button matTooltip="Download QMRF Report of the model" mat-icon-button color="primary" (click)="downloadQMRF()">
        <mat-icon>
            <span class="material-icons-outlined">
                receipt
            </span>
        </mat-icon>
    </button>

    </mat-card-actions>
    
</mat-card>

