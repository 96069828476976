<div *ngIf="isAuthenticated; else isNonAuthenticated">
  <mat-toolbar color="primary" class="toolbar">
    <span>
      
      {{ title }}

    </span>
    <span class="spacer"></span>
    <button mat-button color="white" (click)="logout()">Logout</button>

  </mat-toolbar>
  <!-- <table>
    <tbody>
      <td>
        <img src="assets/SmallLogo.jpg" class="nanosolveit-logo">
      </td>  
      <td>
        <h3>
          <p>
            This work received funding from the European Union's Horizon 2020 research and innovation program via 
            <a href="http://www.nanosolveit.eu">NanoSolveIT project</a>
            under grant agreement number 814572.
          </p>
        </h3>
        
      </td>
    </tbody>
  </table> -->
  
  <div>
    
    <feature-table-component></feature-table-component>
  
  </div>
  <router-outlet></router-outlet>
</div>

<ng-template #isNonAuthenticated>
  <mat-card class="login-card">
    <mat-card-header>
      <mat-card-title>
        <h4>
          Welcome to {{ title }}
        </h4>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      
      <br><br>
      <p>
        {{ desc }}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button color="accent" class="login-button" (click)="login()">Login</button>
    </mat-card-actions>
  </mat-card>
  <div>
    <img src="assets/index.png" class="landing-image">
  </div>
</ng-template>