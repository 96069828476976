import { Component, OnInit, Input ,Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms'
import { Model } from '@euclia/jaqpot-client/dist/models/jaqpot.models';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AskForIdComponent } from "../dialogs/ask-for-id/ask-for-id.component"
import { TITLE, CONVERTER, ACCEPTABLE_VALUES } from '../../assets/project-constants'
import { DialogsService } from '../dialogs/dialogs.service';

@Component({
  selector: 'app-dataset-csv',
  templateUrl: './dataset-csv.component.html',
  styleUrls: ['./dataset-csv.component.scss']
})
export class DatasetCsvComponent implements OnInit {

  myformArray = new FormArray([])
  
  @Output() sendData = new EventEmitter<any>();
  
  constructor(
    private _dialogsService:DialogsService,

    // private dialog: MatDialog
  ) { }

  ngOnInit(){}

  emitData() {
    this.sendData.emit(this.myformArray.controls)
  }

  uploadDataset(files: FileList){
    let reader: FileReader = new FileReader();
    let file: File = files.item(0);
    let currID = "";

    reader.readAsText(file);
    reader.onload = (e) => {
      var _csv = reader.result;
      _csv = _csv.toString()
      const rows = _csv.split(/\r?\n/)  
      let ids = rows[0].split(/,|;/);
      // let dialogRef: MatDialogRef<AskForIdComponent>;
      
      // dialogRef = this.dialog.open(AskForIdComponent);
      // dialogRef.componentInstance.ids = ids
      
      
      this._dialogsService.askForId(rows[0].split(/,|;/)).subscribe(result =>{
          reader.abort()
          currID = result;

          this.myformArray = new FormArray([])

          let pos = ""

          let first = true;
          let mapping = {}
          let rowIter = 0
          rows.forEach((element) => {
            if (first){

              element.split(/,|;/).forEach(item => {
                mapping[element.split(/,|;/).indexOf(item)] = item;
              });
              first = false;
              pos = Object.keys(mapping).find(key => mapping[key] === currID)
            } else {
              const newGroup=new FormGroup({})

              let iter = 0;

              element.split(/,|;/).forEach(item => {
                if (iter.toString()!==pos){
                  
                  if (mapping[iter]=="Diameter (nm)" || mapping[iter]=="Concentration (μΜ)" || mapping[iter]=="Exposure time (h)") {
                    if (item.trim()=="" || isNaN(Number(item))){
                      this._dialogsService.raiseMalformedInput(rowIter,mapping[iter],item)
                      throw new Error('Non numeric values');
                    }
                  } else if(mapping[iter]=="Nanoparticle") {
                    if (!Object.keys(CONVERTER["Nanomaterial"]).includes(item)){
                      this._dialogsService.raiseMalformedInput(rowIter,mapping[iter],item)
                      throw new Error('Unacceptable values');
                    }
                    
                  } else if(mapping[iter]=="Type of Nanoparticle") {
                    if (!(Object.keys(CONVERTER["Type of ENM"])).includes(item)){
                      //Object.keys(CONVERTER["Type of ENM"]
                      this._dialogsService.raiseMalformedInput(rowIter,mapping[iter],item)
                      throw new Error('Unacceptable values');
                    }
                    item = String(CONVERTER["Type of ENM"][item])
                    
                  } else if(mapping[iter]=="Cells") {
                    if (!ACCEPTABLE_VALUES["Cells"].includes(item)){
                      this._dialogsService.raiseMalformedInput(rowIter,mapping[iter],item)
                      throw new Error('Unacceptable values');
                    }

                    if (item !== 'Other'){
                      let index = ACCEPTABLE_VALUES['Cells'].indexOf(item)
                      item = Object.keys(CONVERTER['Cells'])[index].substring(6)
                    }
                    

                  } else {
                    if (!ACCEPTABLE_VALUES["Biochemical metric"].includes(item)){
                      this._dialogsService.raiseMalformedInput(rowIter,mapping[iter],item)
                      throw new Error('Unacceptable values');
                    }

                    if (item !== 'Other'){
                      let index = ACCEPTABLE_VALUES['Biochemical metric'].indexOf(item)
                      item = Object.keys(CONVERTER['Biochemical metric'])[index].substring(19)  
                    }
                  } 

                  newGroup.addControl(mapping[iter],new FormControl(item))
                } else {
                  newGroup.addControl('ID',new FormControl(item))
                }
                iter = iter + 1;
              });

              if (currID == "None"){
                newGroup.addControl('ID',new FormControl(rowIter))
              }
              this.myformArray.push(newGroup)
            }        


            rowIter = rowIter + 1
          })

          this.emitData();
        })

      
    }
    
  }

  
  
  downloadTemplate(){
    var csvData:string = "ID,Nanoparticle,Type of Nanoparticle,Diameter (nm),Concentration (μΜ),Exposure time (h),Cells,Biochemical metric\nFirst,ZnO,Inorganic,639,2.2e-9,12,HCMEC,Cell Metabolic Activity\nSecond,Au,Inorganic,21,0.00194974,24,Other,Lysosomal Uptake\nThird,Carbon Nanotubes,Organic,1.3,32.0787,24,A549,Cell Metabolic Activity\nFourth,SLN,Organic,93.13,0.004132934,24,A549,Cell Metabolic Activity\nFifth,Iron oxide,Inorganic,30,0.02241588,96,PAECs,Cell Metabolic Activity";
    let i = 0;

    var blob = new Blob(["\ufeff"+csvData], { type: 'text/csv; charset=utf-8' });
    var url = window.URL.createObjectURL(blob);
    const datasetName = TITLE + " - Predict Template.csv"
    if(navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, datasetName);
    } else {
      var a = document.createElement("a");
      a.href = url;
      a.download = datasetName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

}
