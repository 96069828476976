<h1 mat-dialog-title>Non numeric values</h1>
<div *ngIf="data.y!='Coating' && data.y != 'Coating Charge' ; else string">
    <div mat-dialog-content>
        <p>
            An attempt to pass non-numeric values was detected.
        </p>
        <p>
            Value "{{ data.value }}" at row {{ data.x }} and column {{ data.y }} is not numeric.
        
        </p> 
    </div>
</div>

<ng-template #string>
    <div *ngIf="data.y=='Coating'; else charge" mat-dialog-content>
        <p>
            Non acceptable Coating value was detected.
        </p>
        <p>
            Value "{{ data.value }}" at row {{ data.x }} and column {{ data.y }} is not included in the acceptable values ('yes', 'no').
        
        </p> 
    </div>
</ng-template>

<ng-template #charge>
    <div mat-dialog-content>
        <p>
            Non acceptable Coating Charge value was detected.
        </p>
        <p>
            Value "{{ data.value }}" at row {{ data.x }} and column {{ data.y }} is not included in the acceptable values ('neutral', 'anionic', 'cationic').
        
        </p> 
    </div>
</ng-template>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>