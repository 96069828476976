<h1 mat-dialog-title>Server-Side Error</h1>
<div mat-dialog-content>
    <p>
        A server-side error has occured.
    </p>
    <p>
        Message from server:  {{ data.msg }}
    </p> 
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>