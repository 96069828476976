import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TITLE } from '../../assets/project-constants'

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
  }

  downloadQMRF(){
    this.http.get("../../assets/qmrf.pdf", {responseType: "blob"}).subscribe(pdf => {
      var blob = new Blob([pdf], { type: 'text/csv; charset=utf-8' });
      var url = window.URL.createObjectURL(blob);
      const datasetName = TITLE + " - QMRF Report.pdf"
      if(navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, datasetName);
      } else {
        var a = document.createElement("a");
        a.href = url;
        a.download = datasetName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);
    })  
  }

  downloadTutorial(){
    this.http.get("../../assets/tutorial.pdf", {responseType: "blob"}).subscribe(pdf => {
      var blob = new Blob([pdf], { type: 'text/csv; charset=utf-8' });
      var url = window.URL.createObjectURL(blob);
      const datasetName = TITLE + " - Tutorial.pdf"
      if(navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, datasetName);
      } else {
        var a = document.createElement("a");
        a.href = url;
        a.download = datasetName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);
    })  
  }
}
