import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AskForIdComponent } from './ask-for-id/ask-for-id.component'
import { MalformedInputComponent } from './malformed-input/malformed-input.component';
import { ServerErrorComponent } from './server-error/server-error.component'


@Injectable({
  providedIn: 'root'
})
export class DialogsService {
  

  constructor(
    private _dialog: MatDialog,
  ){}
  
    public askForId(columns:Array<string>){
      let dialogRef: MatDialogRef<AskForIdComponent>;
      dialogRef = this._dialog.open(AskForIdComponent, {
        data:{
          possibilites: columns
        }
      })
      return dialogRef.afterClosed()
    }

  public raiseMalformedInput(xCoord:any, yCoord:any, val:any){
    let dialogRef: MatDialogRef<MalformedInputComponent>;
    dialogRef = this._dialog.open(MalformedInputComponent,{
      data:{
        x: xCoord,
        y: yCoord,
        value: val
      }
    })
    return dialogRef.afterClosed()
  }

  public raiseServerError(errorMsg: string){
    

    let dialogRef: MatDialogRef<ServerErrorComponent>;
    dialogRef = this._dialog.open(ServerErrorComponent,{
      data:{
        msg: errorMsg
      }
    })
    return dialogRef.afterClosed()
  }
}