import { Component, OnInit, Input, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { JaqpotService } from '../jaqpot-api/jaqpot-api.service';
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort';
import { GUIDE, MODEL_ID, TITLE, CONVERTER } from '../../assets/project-constants'
import { DialogsService } from '../dialogs/dialogs.service';
import {TooltipPosition} from '@angular/material/tooltip';


export interface PredictionFields{
  id: any;
  pred: any;
  doa: any;
  doaVal: any;
}

@Component({
  selector: 'app-predictions',
  templateUrl: './predictions.component.html',
  styleUrls: ['./predictions.component.scss']
})

export class PredictionsComponent implements OnInit, AfterViewInit {

  constructor(
    private _jaqpotApi:JaqpotService,
    private _dialogsService:DialogsService,
  ) { }

  @Input() sendFeatures: Array<Object>;
  @Input() ids: Array<string>;

  calcPrediction:boolean = false;
  predsOK:boolean = false;
  independent = "";
  aValue = null;
  doaValues = []
  tooltiPosition: TooltipPosition = 'right'

  tablePreds = new MatTableDataSource();

  predColumns:string[] = ['id', 'pred', 'doa']
  predsSource: PredictionFields[] = []

  @ViewChild(MatSort) sort: MatSort;


  ngOnInit() {}
  

  ngAfterViewInit() {
    this.tablePreds.sort = this.sort;
  }

  predict(){

    this.predsSource = []    
    this.calcPrediction = true

    this._jaqpotApi.predictDoa(MODEL_ID ,this.sendFeatures).subscribe(m =>{
            this.independent = Object.keys(m.predictions[0])[0]
            const predictions = m.predictions.map(col => col[this.independent]);
            const doa = m.predictions.map(col => col['DOA']);
            
            this.aValue = m.aValue.toFixed(4);
            
            for (let i in this.ids) {
  
              this.predsSource.push({
                  id: this.ids[i],
                  pred: (predictions[i]*100).toFixed(3),
                  doa: doa[i]<this.aValue && 'In' || 'Out',
                  doaVal: doa[i].toFixed(4)
              })
            }

            this.tablePreds.data = this.predsSource;

            this.calcPrediction = false
            this.predsOK = true
    },
    err => {
      this._dialogsService.raiseServerError(err)
        this.calcPrediction = false
        throw new Error(err);

    })
  }

  downloadPredictions() {
    var csvData:string = "";

    let csvObject = [];

    for (let i in this.sendFeatures){
      let currObject = {}
      currObject['ID'] = this.ids[i];

      let bioCFound = false;
      let cellFound = false; 
      let bioCCount = 0;
      let cellCount = 0;
      
      Object.keys(this.sendFeatures[i]).forEach(x=>{
        if (x.indexOf('Cells_') !== -1){
          cellCount = cellCount + 1;

          if (this.sendFeatures[i][x] == 1){
            currObject['Cells'] = x.substring(6).split('_').join(' ');
            cellFound = true;
          }

          if (!cellFound && cellCount==65){
            currObject['Cells'] = "Other"
          }
   
        } else if (x.indexOf('Biochemical_metric_') !== -1){
          bioCCount = bioCCount + 1;

          if (this.sendFeatures[i][x] == 1){
            currObject['Biochemical metric'] = x.substring(19).split('_').join(' ');
            bioCFound = true;
          }

          if (!bioCFound && bioCCount==6){
            currObject['Biochemical metric'] = "Other"
          }
        
        } else if (x == "Nanoparticle"){
          currObject['Nanoparticle'] = Object.keys(CONVERTER["Nanomaterial"])[this.sendFeatures[i][x]];
      
        } else if (x == "Type:_Organic_(O)/inorganic_(I)"){
          if (this.sendFeatures[i][x] == 0){
            currObject['Type of Nanoparticle'] = "Inorganic";
          } else {
            currObject['Type of Nanoparticle'] = "Organic";
          }
      
        } else {
          currObject[x] = this.sendFeatures[i][x]
        }
      })
      
      currObject[this.independent] = this.predsSource[i].pred;
      currObject['DOA'] = this.predsSource[i].doaVal;

      csvObject.push(currObject)
    }
    
    let pre_header = "Leverage Threshold:, "+ this.aValue.toString() + "\nDOA Values should be less than the Leverage Threshold for the observations to be inside the model's Domain of Applicability\n\n";

    let header = "";

    Object.keys(csvObject[0]).forEach(x=>{
      header = header + "," + x 
    })

    header = header.substring(1)

    let values = csvObject.map(o => Object.values(o).join(',')).join('\n');

    csvData += pre_header + header + '\n' + values;
   
    var blob = new Blob(["\ufeff"+csvData], { type: 'text/csv; charset=utf-8' });
    var url = window.URL.createObjectURL(blob);
    const datasetName = TITLE + " - Predictions.csv"
    if(navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, datasetName);
    } else {
      var a = document.createElement("a");
      a.href = url;
      a.download = datasetName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  
  }

  ngOnChanges(changes) {
    this.predict();  
  }

}
