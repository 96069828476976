<link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" rel="stylesheet">

<div class="spinner">

    <div *ngIf="calcPrediction" class="center-spin">
      <h4>Predicting...</h4>
      <mat-spinner>
      </mat-spinner>
    </div>
  
</div>
  

<!-- <div *ngIf="goToPrediction; then predict(); else false">;</div> -->


<div [hidden]="!predsOK">
    <div style="clear:both;"></div>

    <table mat-table [dataSource]="tablePreds" matSort class="mat-elevation-z8 preds-table">

        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th headers mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Endpoint Column -->
        <ng-container matColumnDef="pred">
            <div class= "headers">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cell Viability (%) </th>
            </div>
            
            <td mat-cell *matCellDef ="let element"> {{element.pred}} </td>
        </ng-container>

        <!-- DOA Column -->
        <ng-container matColumnDef="doa">
            <div class= "headers">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Domain Of Applicability </th>
            </div>
            
            <td mat-cell [matTooltip]="'DOA Value: ' + element.doaVal + '&#13;Leverage Threshold: ' + aValue" matTooltipPosition="right" 
            matTooltipClass="multi-line" *matCellDef ="let element"> {{element.doa}} </td>
        </ng-container>

        
        <tr mat-header-row *matHeaderRowDef="predColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: predColumns;"></tr>
    </table>
  
    <div class="down-button">
        <button mat-raised-button class="down-button" color="primary" (click)="downloadPredictions()">Download Predictions</button>
    </div>

</div>
